/*===============================
=            Choices            =
===============================*/
.choices {
  position: relative;
  margin-bottom: 24px;
  font-size: 16px;
}

.choices:focus {
  outline: none;
}

.choices:last-child {
  margin-bottom: 0;
}

.choices.is-disabled .choices__inner,
.choices.is-disabled .choices__input {
  background-color: #eaeaea;
  cursor: not-allowed;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.choices.is-disabled .choices__item {
  cursor: not-allowed;
}

.choices [hidden] {
  display: none !important;
}

.choices[data-type*='select-one'] {
  cursor: pointer;
}

.choices[data-type*='select-one'] .choices__inner {
  padding-bottom: 7.5px;
}

.choices[data-type*='select-one'] .choices__input {
  display: block;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #dddddd;
  background-color: #ffffff;
  margin: 0;
}

.choices[data-type*='select-one'] .choices__button {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
  padding: 0;
  background-size: 8px;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -10px;
  margin-right: 25px;
  height: 20px;
  width: 20px;
  border-radius: 10em;
  opacity: 0.5;
}

.choices[data-type*='select-one'] .choices__button:hover, .choices[data-type*='select-one'] .choices__button:focus {
  opacity: 1;
}

.choices[data-type*='select-one'] .choices__button:focus {
  box-shadow: 0px 0px 0px 2px #00bcd4;
}

.choices[data-type*='select-one'] .choices__item[data-value=''] .choices__button {
  display: none;
}

.choices[data-type*='select-one']:after {
  content: "\f107";
  font: normal normal normal 14px/1 FontAwesome;
  position: absolute;
  right: 13.5px;
  top: 14px;
  pointer-events: none;
  transition: $dropdown-toggle-arrow-transition;
}

.choices[data-type*='select-one'].is-open:after {
  transform: $dropdown-toggle-arrow-transform;
}

.choices[data-type*='select-one'][dir='rtl']:after {
  left: 11.5px;
  right: auto;
}

.choices[data-type*='select-one'][dir='rtl'] .choices__button {
  right: auto;
  left: 0;
  margin-left: 25px;
  margin-right: 0;
}

.choices[data-type*='select-multiple'] .choices__inner,
.choices[data-type*='text'] .choices__inner {
  cursor: text;
}

.choices[data-type*='select-multiple'] .choices__button,
.choices[data-type*='text'] .choices__button {
  position: relative;
  display: inline-block;
  margin-top: 0;
  margin-right: -6px;
  margin-bottom: 0;
  margin-left: 8px;
  padding-left: 17px;
  border-left: 1px solid rgba($white, .3);
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
  background-size: 8px;
  width: 8px;
  line-height: 1;
  opacity: 0.75;
  border-radius: 0;
  transition: $transition-base;
}

.choices[data-type*='select-multiple'] .choices__button:hover, .choices[data-type*='select-multiple'] .choices__button:focus,
.choices[data-type*='text'] .choices__button:hover,
.choices[data-type*='text'] .choices__button:focus {
  opacity: 1;
}

.choices__inner {
  display: flex;
  align-items: center;
  vertical-align: top;
  width: 100%;
  // background-color: #f9f9f9;
  padding: $input-padding-y $input-padding-x;
  border: 1px solid $input-border-color;
  border-radius: .5rem;
  font-size: 14px;
  min-height: 40px;
  overflow: hidden;
}

.is-focused .choices__inner,
.is-open .choices__inner {
  border-color: #b7b7b7;
}

.is-open .choices__inner {
  border-radius: 2.5px 2.5px 0 0;
}

.is-flipped.is-open .choices__inner {
  border-radius: 0 0 2.5px 2.5px;
}

.choices__list {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.choices__list--single {
  display: inline-block;
  // padding: 4px 16px 4px 4px;
  width: 100%;
}

[dir='rtl'] .choices__list--single {
  padding-right: 4px;
  padding-left: 16px;
}

.choices__list--single .choices__item {
  width: 100%;
}

.choices__list--multiple {
  display: inline;
}

.choices__list--multiple .choices__item {
  display: inline-block;
  vertical-align: middle;
  border-radius: 20px;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 3.75px;
  margin-bottom: 3.75px;
  background-color: $dark-gradient-state;
  border: 1px solid $dark-gradient-state;
  color: #ffffff;
  word-break: break-all;
  box-sizing: border-box;
}

.choices__list--multiple .choices__item[data-deletable] {
  padding-right: 5px;
}

[dir='rtl'] .choices__list--multiple .choices__item {
  margin-right: 0;
  margin-left: 3.75px;
}

.choices__list--multiple .choices__item.is-highlighted {
  background-color: #00a5bb;
  border: 1px solid #008fa1;
}

.is-disabled .choices__list--multiple .choices__item {
  background-color: #aaaaaa;
  border: 1px solid #919191;
}

.choices__list--dropdown {
  visibility: hidden;
  z-index: 1;
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  top: 100%;
  margin-top: -1px;
  border-bottom-left-radius: 2.5px;
  border-bottom-right-radius: 2.5px;
  word-break: break-all;
  will-change: visibility;
}

.choices__list--dropdown.is-active {
  visibility: visible;
}

.is-open .choices__list--dropdown {
  border-color: #b7b7b7;
}

.is-flipped .choices__list--dropdown {
  top: auto;
  bottom: 200%;
  margin-top: 0;
  margin-bottom: -1px;
  border-radius: $choices-border-radius;
}

.choices__list--dropdown .choices__list {
  position: relative;
  max-height: 300px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  will-change: scroll-position;
}

.choices__list--dropdown .choices__item {
  position: relative;
  padding: $dropdown-item-padding-y $dropdown-item-padding-x;
  @include font-size($dropdown-font-size);
  border-radius: $border-radius-md;
  transition: background-color $dropdown-transition-time, color $dropdown-transition-time;
}

[dir='rtl'] .choices__list--dropdown .choices__item {
  text-align: right;
}

@media (min-width: 640px) {
  .choices__list--dropdown .choices__item--selectable {
    padding-right: 100px;
  }
  .choices__list--dropdown .choices__item--selectable:after {
    content: attr(data-select-text);
    font-size: 12px;
    opacity: 0;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity $dropdown-transition-time;
  }
  [dir='rtl'] .choices__list--dropdown .choices__item--selectable {
    text-align: right;
    padding-left: 100px;
    padding-right: 10px;
  }
  [dir='rtl'] .choices__list--dropdown .choices__item--selectable:after {
    right: auto;
    left: 10px;
  }
}

.choices__list--dropdown .choices__item--selectable.is-highlighted {
  background-color: #f2f2f2;
}

.choices__list--dropdown .choices__item--selectable.is-highlighted:after {
  opacity: 0.5;
}

.choices__item {
  cursor: default;
}

.choices__item--selectable {
  cursor: pointer;
}

.choices__item--disabled {
  cursor: not-allowed;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  opacity: 0.5;
}

.choices__heading {
  font-weight: 600;
  font-size: 12px;
  padding: 10px;
  border-bottom: 1px solid #f7f7f7;
  color: gray;
}

.choices__button {
  text-indent: -9999px;
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  border: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.choices__button:focus {
  outline: none;
}

.choices__input {
  display: inline-block;
  vertical-align: baseline;
  background-color: $white;
  font-size: 14px;
  margin-bottom: 5px;
  border: 0;
  border-radius: 0;
  max-width: 100%;
  padding: 4px 0 4px 2px;
}

.choices__input:focus {
  outline: 0;
}

[dir='rtl'] .choices__input {
  padding-right: 2px;
  padding-left: 0;
}

.choices__placeholder {
  opacity: 0.5;
}

/*=====  End of Choices  ======*/


///////////// Creative Tim Changes

.is-open .choices__inner{
  border-radius: $choices-border-radius;
}

.choices__list--dropdown{
  display: block;
  opacity: 0;
  top: 19px;
  transform-origin: $dropdown-transform-origin;
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform,box-shadow;
  border: none;
  border-radius: $choices-border-radius;
  box-shadow: $choices-box-shadow;
  transform: $choices-transform;
  padding: $choices-padding-y $choices-padding-x;
  transition: $choices-transition;

  &.is-active{
    transform: $dropdown-transform-show;
    opacity: 1;
    display: block;
    pointer-events: auto;

    &:before {
      top: $dropdown-hover-arrow-active-top;
    }
  }

  // .is-flipped &.is-active {
  //   transform: $dropup-transform-show;
  // }

  &:before {
    font-family: "FontAwesome";
    content: "\f0d8";
    position: absolute;
    top: 0;
    left: $dropdown-animation-arrow-left-position;
    right: auto;
    font-size: $dropdown-animation-arrow-font-size;
    color: $white;
    transition: $dropdown-animation-arrow-transition;
  }
}

// .choices__item--choice{
//   color: $dark;
// }

.choices[data-type*=select-one] .choices__input{
  border-bottom-color: $gray-100;
}

.choices__list--dropdown .choices__item--selectable.is-highlighted{
  background: $dropdown-link-hover-bg;
  color: $dropdown-link-hover-color;
}

.is-focused .choices__inner,
.is-open .choices__inner{
  box-shadow: $input-focus-box-shadow;
  border-color: $input-focus-border-color;
}

@each $name, $value in $theme-colors {
  @if $name == 'dark' {
    $value: $dark-gradient-state;
  }
  .choices-#{$name} {
    background-color: $value;
  }
}



/////////////
