//
// Labels
//

label,
.form-label {
  font-size: $form-label-font-size;
  font-weight: $form-label-font-weight;
  margin-bottom: $form-label-margin-bottom;
  color: $form-label-color;
  margin-left: $form-label-margin-left;
}
