/*!

=========================================================
* Soft UI Design System - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-design-system
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (site.license)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// {{ site.product.name }} components

// Mixin
@import "mixins/mixins";

// Core Components - extra styling
@import "alert";
@import "avatars/avatar";
@import "buttons";
@import "cards";
@import "dropdown";
@import "forms/forms";
@import "footer";
@import "gradients";
@import "header";
@import "info-areas";
@import "misc";
@import "navbar";
@import "nav";
@import "pagination";
@import "popovers";
@import "progress";
@import "typography";
@import "tooltips";
@import "utilities";

// Plugins
@import "vendor/plugins";
