//
// Avatar group
//

// General styles

.avatar-group {
	.avatar {
		position: relative;
		z-index: $avatar-group-zindex;
		border: $avatar-group-border solid $card-bg;

		&:hover {
			z-index: $avatar-group-zindex-hover;
		}
	}

	.avatar + .avatar {
		margin-left: $avatar-group-double;

	}
}
